// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-archive-js": () => import("./../src/pages/blog/archive.js" /* webpackChunkName: "component---src-pages-blog-archive-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-checkout-complete-js": () => import("./../src/pages/checkout/complete.js" /* webpackChunkName: "component---src-pages-checkout-complete-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-how-it-works-js": () => import("./../src/pages/how_it_works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-offer-confirm-js": () => import("./../src/pages/offer/confirm.js" /* webpackChunkName: "component---src-pages-offer-confirm-js" */),
  "component---src-pages-offer-index-js": () => import("./../src/pages/offer/index.js" /* webpackChunkName: "component---src-pages-offer-index-js" */),
  "component---src-pages-real-results-js": () => import("./../src/pages/real_results.js" /* webpackChunkName: "component---src-pages-real-results-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-templates-compare-js": () => import("./../src/templates/compare.js" /* webpackChunkName: "component---src-templates-compare-js" */),
  "component---src-templates-discount-js": () => import("./../src/templates/discount.js" /* webpackChunkName: "component---src-templates-discount-js" */),
  "component---src-templates-integration-js": () => import("./../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pricing-js": () => import("./../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */)
}

